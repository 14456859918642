<template>
  <div class="merchantcenter_page">
    <div
      style="
        min-height: 100%;
        box-shadow: 10px 10px 10px rgb(0 0 0 / 35%);
        background: #fff;
        padding: 30px;
        box-sizing: border-box;
      "
    >
      <div class="page_title">{{ language.RateConfiguration }}</div>
      <el-tabs
        style="margin-top: 10px"
        v-model="activeName"
        type="card"
        @tab-click="handleClick"
      >
        <el-tab-pane
          v-for="(item, index) in Currencylist"
          :key="index"
          :label="item"
          :name="item"
        ></el-tab-pane>
      </el-tabs>

      <div
        style="
          border: 1px solid #e4e7ed;
          border-top: none;
          padding: 10px;
          box-sizing: border-box;
        "
      >
        <div>
          <div>{{ language.Userrechargefeeconfiguration }}</div>
          <div style="width: 100%">
            <el-table
              :data="tableData1"
              border
              style="width: 100%"
              stripe
              v-loading="loading"
              :header-cell-style="{ background: '#fafafa' }"
            >
              <!-- 序号 -->
              <el-table-column type="index" :label="language.SerialNumber" width="70">
              </el-table-column>
              <!-- 渠道 TransactionChannel -->
              <el-table-column :label="language.Customname" min-width="10%">
                <template slot-scope="scope">
                  <div v-if="languagetype == 'CHS' || languagetype == 'TC'">
                    {{ scope.row.name }}
                  </div>
                  <div v-else>{{ scope.row.en_name }}</div>
                </template>
              </el-table-column>
              <!-- 渠道 TransactionChannel -->
              <el-table-column :label="language.TransactionChannel" min-width="10%">
                <template slot-scope="scope">
                  <div v-if="languagetype == 'CHS' || languagetype == 'TC'">
                    {{ scope.row.passageConf.name }}
                  </div>
                  <div v-else>{{ scope.row.passageConf.en_name }}</div>
                </template>
              </el-table-column>
              <!-- 商户手续费 -->
              <el-table-column :label="language.Rechargehandlingfee" min-width="20%">
                <template slot-scope="scope">
                  <div>
                    <div class="Modify_information">
                      <div style="display: flex; align-items: center">
                        {{ scope.row.payServiceFeeRate }}
                        <span v-if="activeName != 'USDT'">%+</span>
                        <span v-if="activeName == 'USDT'">USDT</span>
                        <span v-if="activeName != 'USDT'">
                          {{ scope.row.payServiceFeeFixed }}</span
                        ><span v-if="activeName != 'USDT'">{{ activeName }}</span>
                      </div>
                      <div v-if="rules.mchusersetPayConfig">
                        <el-button
                          size="mini"
                          type="primary"
                          @click="Modify(scope.row, 1)"
                          >{{ language.Modify }}</el-button
                        >
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <!-- 平台手续费 -->
              <!-- :label="language.Platformhandlingfees + '(%)'" -->
              <el-table-column :label="language.Platformhandlingfees" min-width="15%">
                <template slot-scope="scope">
                  <div>
                    {{
                      (
                        Number(scope.row.passageConf.payServiceFeeRate) +
                        Number(scope.row.platformPayServiceFeeRate)
                      ).toFixed(2)
                    }}
                    <span v-if="activeName != 'USDT'">%+</span>
                    <span v-if="activeName == 'USDT'">USDT</span>
                    <span v-if="activeName != 'USDT'">
                      {{
                        (
                          Number(scope.row.passageConf.payServiceFeeFixed) +
                          Number(scope.row.platformPayServiceFeeFixed)
                        ).toFixed(2)
                      }}</span
                    ><span v-if="activeName != 'USDT'">{{ activeName }}</span>
                  </div>
                </template>
              </el-table-column>
              <!-- 代收金额限制 -->
              <el-table-column
                prop="payQuotaPeriod"
                :label="language.Collectionamountlimit"
                width="200"
              >
              </el-table-column>
              <!-- 总手续费 -->
              <!-- <el-table-column
                prop="channel_name"
                :label="language.Totalhandlingfee + '(%)'"
                min-width="10%"
              >
              </el-table-column> -->
              <!-- 开关 -->
              <el-table-column
                prop="mch_order_no"
                :label="language.Invoicing"
                min-width="10%"
              >
                <template slot-scope="scope">
                  <el-switch
                    v-if="scope.row.paySwitch == '-1' || !rules.mchusersetPayConfig"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-value="1"
                    inactive-value="0"
                    disabled
                  >
                  </el-switch>
                  <el-switch
                    v-if="scope.row.paySwitch != '-1' && rules.mchusersetPayConfig"
                    @change="stateChanged(scope.row)"
                    v-model="scope.row.paySwitch"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-value="1"
                    inactive-value="0"
                  >
                  </el-switch>
                </template>
              </el-table-column>
              <!-- 推荐 -->
              <el-table-column :label="language.recommend" min-width="10%">
                <template slot-scope="scope">
                  <el-switch
                    @change="stateChanged(scope.row)"
                    v-model="scope.row.isRecommend"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-value="1"
                    inactive-value="0"
                  >
                  </el-switch>
                </template>
              </el-table-column>
            </el-table>
            <div class="paging">
              <el-pagination
                v-loading="loading"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                current-page.sync="10"
                :page-sizes="[10, 10, 15, 20, 25, 30]"
                :page-size="limit2"
                layout="sizes, prev, pager, next"
                :total="pages2"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
      <el-dialog
        :title="language.Modify"
        :visible.sync="dialogVisible"
        width="30%"
        :close-on-click-modal="false"
      >
        <div>
          <div>{{ language.TransactionChannel }}</div>
          <div style="margin-top: 10px">
            <el-input
              v-if="languagetype == 'CHS'"
              placeholder=""
              v-model="msg.name"
              :disabled="true"
            >
            </el-input>
            <el-input
              v-else
              placeholder=""
              v-model="msg.en_name"
              :disabled="true"
            ></el-input>
          </div>
          <!-- 充值费率 -->
          <div style="margin-top: 20px" v-if="Modifytype == 1">
            {{ language.Rechargehandlingfee }}
          </div>
          <div
            style="margin-top: 10px; display: flex; align-items: center"
            v-if="Modifytype == 1"
          >
            <el-input
              placeholder=""
              @keyup.native="
                msg.payServiceFeeRate = msg.payServiceFeeRate.replace(
                  /^\D*(\d*(?:\.\d{0,2})?).*$/g,
                  '$1'
                )
              "
              type="number"
              v-model="msg.payServiceFeeRate"
            >
            </el-input
            ><span v-if="activeName != 'USDT'" style="min-width: 40px">%</span>
            <span v-if="activeName == 'USDT'" style="min-width: 40px">USDT</span>
          </div>
          <!-- 充值固定值 -->
          <div style="margin-top: 20px" v-if="Modifytype == 1 && activeName != 'USDT'">
            {{ language.FFFixedFee }}
          </div>
          <div
            style="margin-top: 10px; display: flex; align-items: center"
            v-if="Modifytype == 1 && activeName != 'USDT'"
          >
            <el-input
              placeholder=""
              @keyup.native="
                msg.payServiceFeeFixed = msg.payServiceFeeFixed.replace(
                  /^\D*(\d*(?:\.\d{0,2})?).*$/g,
                  '$1'
                )
              "
              type="number"
              v-model="msg.payServiceFeeFixed"
            >
            </el-input>
            <span style="white-space: nowrap; min-width: 40px">{{ activeName }}</span>
          </div>
          <!-- 提现费率 -->
          <div style="margin-top: 20px" v-if="Modifytype == 2 && activeName != 'USDT'">
            {{ language.Withdrawalhandlingfee }}
          </div>
          <div
            style="margin-top: 10px; display: flex; align-items: center"
            v-if="Modifytype == 2 && activeName != 'USDT'"
          >
            <el-input
              placeholder=""
              type="number"
              @keyup.native="
                msg.transferServiceFeeRate = msg.transferServiceFeeRate.replace(
                  /^\D*(\d*(?:\.\d{0,2})?).*$/g,
                  '$1'
                )
              "
              v-model="msg.transferServiceFeeRate"
            >
            </el-input
            ><span style="min-width: 40px">%</span>
          </div>
          <!-- 充值固定值 -->
          <div style="margin-top: 20px" v-if="Modifytype == 2">
            {{ language.FFFixedFee }}
          </div>
          <div
            style="margin-top: 10px; display: flex; align-items: center"
            v-if="Modifytype == 2"
          >
            <el-input
              placeholder=""
              type="number"
              @keyup.native="
                msg.transferServiceFeeFixed = msg.transferServiceFeeFixed.replace(
                  /^\D*(\d*(?:\.\d{0,2})?).*$/g,
                  '$1'
                )
              "
              v-model="msg.transferServiceFeeFixed"
            >
            </el-input>
            <span style="white-space: nowrap; min-width: 40px">{{ activeName }}</span>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">{{ language.Cancel }}</el-button>
          <el-button type="primary" @click="truebtn()">{{
            language.determine
          }}</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import rules from "../../common/js/rules";
// usersetPayConfig
import { usergetPayConf, usersetPayConfig } from "../../api/index.js";
export default {
  data() {
    return {
      languagetype: "",
      language: "", //语言
      tableData1: [],
      tableData2: [],
      Currencylist: [],
      loading: false,
      userinfo: "",
      dialogVisible: false,
      msg: "",
      activeName: "",
      page: 1,
      pages: 1,
      limit: 10,
      page2: 1,
      pages2: 1,
      limit2: 10,
      loading2: false,
      Modifytype: 1,
      rules: "",
    };
  },
  created() {
    // 判断语言
    this.changelanguage();
    this.init();
  },
  watch: {
    // 判断语言
    "$store.state.language"() {
      this.changelanguage();
    },
  },
  mounted() {
    this.rules = rules.rules().msg;
  },

  methods: {
    init() {
      let data = {
        limit: 100,
        page: 1,
      };
      usergetPayConf(data).then((res) => {
        // Currencylist
        res.data.list.map((item) => {
          if (!this.Currencylist.includes(item.currency)) {
            this.Currencylist.push(item.currency);
          }
        });
        this.activeName = this.Currencylist[0];
        this.getpay();
        // this.gettransfer();
      });
    },
    // 获取代付数据
    getpay() {
      let data = {
        page: this.page,
        limit: this.limit,
        currency: this.activeName,
      };
      usergetPayConf(data).then((res) => {
        this.tableData1 = res.data.list;
        this.pages1 = res.data.count;
        this.tableData1.map((item) => {
          item.paySwitch = String(item.paySwitch);
          item.isRecommend = String(item.isRecommend);
        });
      });
    },
    stateChanged(e) {
      usersetPayConfig(e).then(() => {
        this.getpay();
        // this.gettransfer();
        this.$message({
          message: this.language.Edit_succeeded,
          type: "success",
          duration: 2000,
        });
      });
    },
    truebtn() {
      usersetPayConfig(this.msg).then(() => {
        this.getpay();
        // this.gettransfer();
        this.dialogVisible = false;
        this.$message({
          message: this.language.Edit_succeeded,
          type: "success",
          duration: 2000,
        });
      });
    },
    // 获取代支数据
    gettransfer() {
      let data = {
        page: this.page2,
        limit: this.limit2,
        currency: this.activeName,
      };
      usergetPayConf(data).then((res) => {
        this.tableData2 = res.data.list;
        this.pages2 = res.data.count;
        this.tableData2.map((item) => {
          item.transferSwitch = String(item.transferSwitch);
        });
      });
    },

    // 切换每页条数
    handleSizeChange(val) {
      this.page = 1;
      this.limit = val;
      this.getpay();
    },
    //选择页数
    handleCurrentChange(val) {
      this.page = val;
      this.getpay();
    },
    // 切换每页条数
    handleSizeChange2(val) {
      this.page2 = 1;
      this.limit2 = val;
      // this.gettransfer();
    },
    //选择页数
    handleCurrentChange2(val) {
      this.page2 = val;
      // this.gettransfer();
    },
    handleClick(tab) {
      this.activeName = tab.label;
      this.getpay();
      // this.gettransfer();
    },
    // 修改按钮
    Modify(e, i) {
      this.Modifytype = i;
      this.dialogVisible = true;
      this.msg = JSON.parse(JSON.stringify(e));
    },
    // 获取语言
    changelanguage() {
      this.languagetype = this.$store.state.language;
      this.language = this.$units();
    },
  },
};
</script>
<style scoped>
.merchantcenter_page {
  min-height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.iconeye3 {
  display: inline-block;
  width: 33px;
  height: 22px;
  position: absolute;
  z-index: 9;
  top: 27px;
  cursor: pointer;
  right: 0;
  background: url("../../static/icon-login.png") no-repeat 0 -43px;
}

* /deep/ .el-tabs__header {
  margin: 0;
}

.iconeye4 {
  display: inline-block;
  width: 33px;
  height: 22px;
  position: absolute;
  z-index: 9;
  top: 27px;
  cursor: pointer;
  right: 0;
  background: url("../../static/icon-login.png") no-repeat -55px -43px;
}

.page_title {
  font-size: 26px;
  font-weight: 600;
}

.Modify_information {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.paging {
  width: 100%;
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
}

* /deep/ .el-table .cell {
  white-space: nowrap;
}

* /deep/ input::-webkit-outer-spin-button,
* /deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

* /deep/ input[type="number"] {
  -moz-appearance: textfield;
}
</style>
